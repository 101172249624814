/**
 * Home page scripts
 */

"use strict";

//
const homeScripts = () => {
  /**
   * Handle Video autoplay on home page. Play / Pause video when it's in viewport
   */
  const homeVideo = document.querySelector(".home-video");

  if (homeVideo !== null) {
    const videoContainer = document.querySelector(".home-intro__video");

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const callback = function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          homeVideo.play();
        } else {
          homeVideo.pause();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    observer.observe(videoContainer);
  }
};

homeScripts();
